import React, { FC } from 'react';

import tokens from '../../themes/oxxio/tokens';
import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const SemiDetachedHouse: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M74 70H40C38.9 70 38 69.1 38 68V33.31C38 32.88 38.14 32.45 38.4 32.11L53.84 11.63C54.6 10.62 55.77 10.04 57.03 10.04C58.3 10.04 59.46 10.62 60.23 11.64L75.59 32.12C75.85 32.47 75.99 32.89 75.99 33.32V68.01C75.99 69.11 75.09 70.01 73.99 70.01L74 70ZM42 66H72V33.97L57.04 14.02L42 33.97V65.99V66Z"
      fill={color ?? tokens.iconColors.iconSecondary}
    />
    <path d="M40 70H6C4.9 70 4 69.1 4 68V34.68C4 33.35 4.45 32.04 5.27 30.99L19.8 11.63C20.56 10.61 21.73 10.03 23 10.03C24.27 10.03 25.44 10.61 26.2 11.63L40.76 31.02C41.56 32.04 42.01 33.35 42.01 34.68V68C42.01 69.1 41.11 70 40.01 70H40ZM8 66H38V34.68C38 34.24 37.85 33.8 37.58 33.45L23 14.03L8.44 33.42C8.15 33.8 8 34.24 8 34.68V66Z" />
  </Illustration>
);
