import React, { FC } from 'react';

import tokens from '../../themes/oxxio/tokens';
import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Apartment: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M68 70C66.9 70 66 69.1 66 68V12C66 10.9 66.9 10 68 10C69.1 10 70 10.9 70 12V68C70 69.1 69.1 70 68 70ZM14 68V12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12V68C10 69.1 10.9 70 12 70C13.1 70 14 69.1 14 68Z"
      fill={color ?? tokens.iconColors.iconSecondary}
    />
    <path d="M66 56H14C11.79 56 10 54.21 10 52V28C10 25.79 11.79 24 14 24H66C68.21 24 70 25.79 70 28V52C70 54.21 68.21 56 66 56ZM14 28V52H66V28H14Z" />
  </Illustration>
);
