import React, { FC } from 'react';

import tokens from '../../themes/oxxio/tokens';
import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const HeatpumpShed: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M66.7 73H16.3C12.83 73 10 70.18 10 66.7V31.11C10 29.65 10.51 28.23 11.44 27.1L26.15 9.30001C27.35 7.85001 29.12 7.01001 31 7.01001C32.88 7.01001 34.65 7.84001 35.85 9.30001L50.56 27.1C51.49 28.23 52 29.65 52 31.11V48.71C52 49.98 53.03 51.01 54.3 51.01H66.71C70.18 51.01 73.01 53.83 73.01 57.31V66.72C73.01 70.19 70.19 73.02 66.71 73.02L66.7 73ZM31 11.01C30.31 11.01 29.67 11.31 29.23 11.84L14.52 29.64C14.18 30.05 13.99 30.57 13.99 31.1V66.7C13.99 67.97 15.02 69 16.29 69H66.7C67.97 69 69 67.97 69 66.7V57.29C69 56.02 67.97 54.99 66.7 54.99H54.29C50.82 54.99 47.99 52.17 47.99 48.69V31.09C47.99 30.56 47.8 30.04 47.46 29.63L32.76 11.83C32.32 11.3 31.68 11 30.99 11L31 11.01Z"
      fill={color ?? tokens.iconColors.iconSecondary}
    />
    <path d="M63 55H54C50.69 55 48 52.31 48 49V45C48 41.69 50.69 39 54 39H63C66.31 39 69 41.69 69 45V49C69 52.31 66.31 55 63 55ZM54 43C52.9 43 52 43.9 52 45V49C52 50.1 52.9 51 54 51H63C64.1 51 65 50.1 65 49V45C65 43.9 64.1 43 63 43H54Z" />
  </Illustration>
);
