import React, { FC } from 'react';

import tokens from '../../themes/oxxio/tokens';
import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const HeatpumpFront: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M53 45C53 46.1 52.1 47 51 47H50C48.9 47 48 46.1 48 45C48 43.9 48.9 43 50 43H51C52.1 43 53 43.9 53 45ZM41 52C39.9 52 39 52.9 39 54V63C39 64.1 39.9 65 41 65C42.1 65 43 64.1 43 63V54C43 52.9 42.1 52 41 52ZM43 43H41C39.9 43 39 43.9 39 45V47C39 48.1 39.9 49 41 49C42.1 49 43 48.1 43 47C44.1 47 45 46.1 45 45C45 43.9 44.1 43 43 43ZM62 34.87V66.91C62 70.26 59.26 72.99 55.9 72.99H24.11C20.74 72.99 18.01 70.26 18.01 66.91V34.87C18.01 33.43 18.52 32.04 19.45 30.94L35.39 12.14C36.55 10.77 38.25 9.97998 40.05 9.97998C41.85 9.97998 43.55 10.77 44.71 12.15L60.56 30.94C61.48 32.04 61.99 33.43 61.99 34.86L62 34.87ZM31.17 69C31.63 69 32 68.62 32 68.17V60.84C32 60.38 31.62 60.01 31.17 60.01H29.84C29.38 60.01 29.01 60.39 29.01 60.84V68.17C29.01 68.63 29.39 69 29.84 69H31.17ZM58 47C56.9 47 56 46.1 56 45C56 43.9 56.9 43 58 43V34.87C58 34.38 57.83 33.91 57.51 33.53L41.66 14.74C41.25 14.26 40.68 13.99 40.05 13.99C39.42 13.99 38.85 14.25 38.44 14.73L22.5 33.53C22.18 33.91 22.01 34.38 22.01 34.87V66.91C22.01 68.06 22.95 68.99 24.11 68.99H25.09C25.04 68.72 25.01 68.44 25.01 68.16V60.83C25.01 58.16 27.18 56 29.84 56H31.17C33.84 56 36 58.17 36 60.83V68.16C36 68.45 35.96 68.72 35.92 68.99H55.9C57.06 68.99 58 68.06 58 66.91V46.99V47Z"
      fill={color ?? tokens.iconColors.iconSecondary}
    />
    <path d="M56 73H45C41.69 73 39 70.31 39 67V62C39 58.69 41.69 56 45 56H56C59.31 56 62 58.69 62 62V67C62 70.31 59.31 73 56 73ZM45 60C43.9 60 43 60.9 43 62V67C43 68.1 43.9 69 45 69H56C57.1 69 58 68.1 58 67V62C58 60.9 57.1 60 56 60H45Z" />
  </Illustration>
);
